import * as Yup from 'yup';
export var bookFormValidation = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    level: Yup.object().nullable().required('level is required'),
    section: Yup.object().nullable().optional(),
    price: Yup.number().moreThan(0).nullable().required('Price is required'),
    image: Yup.mixed().nullable().optional(),
    //   type: Yup.string()
    //     .required('Operation type is required')
    //     .oneOf(Object.values(OperationType), 'Invalid Operation type value'),
});
